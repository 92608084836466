import axios from 'axios';

export const GreenhouseApi = () => {
  return {
    getDepartments: () => {
      return axios.get('https://boards-api.greenhouse.io/v1/boards/kushki/departments', {
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(response => {
          return response.data
        })
    },
    getJobByDepartment: (id: string) => {
      return axios.get(`https://boards-api.greenhouse.io/v1/boards/kushki/departments/${id}`, {
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(response => {
          return response.data
        })
    },
    getJobs: () => {
      return axios.get(`https://boards-api.greenhouse.io/v1/boards/kushki/jobs`, {
        // headers: {
        //   'Authorization': 'Basic OGZiY2Y5MTE3M2Q1YjFkYjQxMTYzZmE3YmI2MGU0NDUtNDo='
        // }
      })
        .then(response => {
          return response.data
        })
    },
    getOffices: () => {
      return axios.get(`https://boards-api.greenhouse.io/v1/boards/kushki/offices`, {
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(response => {
          return response.data
        })
    }
  }
}