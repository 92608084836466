import { graphql } from 'gatsby';
import * as React from 'react'
import Footer from '../components/Footer/Footer';
import { LocaleTypes } from '../data/locales';
import { GreenhouseApi } from '../services/greenhouse';
import IndexLayout from '../layouts';
import bgImg from '../components/WorkWithUs/WorkWithUsJob/assets/images/hero.png';

import '../components/WorkWithUs/WorkWithUsJob/styles.scss';
import { FormattedMessage } from 'react-intl';

interface WorkWithUsJobProps {
  data: {
    jobInfo: {
      edges: Array<[
        node: {
          title: string;
          content: string;
          active: boolean;
          location: {
            name: string;
          }
          id: number;
          live: boolean;
          job_id: number;
        }
      ]> | [];
    }
  }
  pathContext: {
    id: number;
    job_id: number;
    locale: LocaleTypes;
  };
  location: Location;
}
const WorkWithUsJob: React.SFC<WorkWithUsJobProps> = ({data, pathContext, location}) => {

  const [tab, setTab] = React.useState('info');
  const [department, setDepartment] = React.useState([]);
  const dataApi = GreenhouseApi();
  
  
  React.useEffect(() => {
    if(window) {
      const script = document.createElement('script');
      script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=kushki';
      script.async = true;
      document.body.appendChild(script);
    }
    dataApi.getDepartments()
    .then(res => {
      flatDepartments(res.departments);
    });
  }, []);
  
  const changeTab = (tabName: string) => {
    setTab(tabName)
    if (tabName === 'form') {
      if (window) {
        setTimeout(() => {
          window.Grnhse.Iframe.load(pathContext.id);
        }, 100);
      }
    }
  }

  const flatDepartments = (departments: any) => {
    let arrDepartments: any = [];
    departments.map((department: any) => {
      if(department.jobs.length > 0) {
        arrDepartments.push({
          id: department.id,
          name: department.name,
          jobs: department.jobs.map((job: any) => {
            return job.internal_job_id
          })
        })
      }
    })
    setDepartment(arrDepartments);
  }

  const departmentName = (id: number) => {
    const departmentJob = department.filter((itemDep: any) => itemDep.jobs.includes(id));
    const name = departmentJob[0] ? departmentJob[0].name : '';
    return name;
  }

  const content = ( ): React.ReactNode => {

    console.log("JOB INFO",data.jobInfo);

    const html = data.jobInfo.edges[0].node.content;
    const dataJob = data.jobInfo.edges[0].node;
    return (
      <>
      <section className="bg-ligth-gray mt-layout-5"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          height: '345px',
          marginTop: '4rem',
          width: '100%',
        }} >
        <div className="container pt-layout-5">
          <div className="row py-layout-3">
            <div className="col-md-12 hero-container">
              <h2 className="text-white">{dataJob.title}</h2>
              <p className="text-white lead" >
                <FormattedMessage id="work_with_us.job_info.location"/> <span className="text-white lead">{dataJob.location.name}</span></p>
              <p className="text-white lead"><FormattedMessage id="work_with_us.job_info.department"/><span className="text-white lead">{departmentName(dataJob.job_id)}</span></p>
              {
                tab !== 'form' &&
                  <button className="btn--basic text-primary" onClick={ () => changeTab('form') }>
                    <FormattedMessage id="work_with_us.job_info.apply"/>
                  </button>
              }
            </div>
          </div>
        </div>
      </section>
      <section className="contain-tabs" >
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-layout-1">
              <ul className="nav nav-pills d-flex justify-content-start">
                <li className="nav-item">
                  <a className={ `mr-2 ${tab === 'info' ? 'active-tab-a' : 'inactive-tab-a'}` } onClick={ () => changeTab('info') }>Descripción del puesto</a>
                </li>
                <li className="nav-item">
                  <a className={ ` ml-2 ${tab === 'form' ? 'active-tab-a' : 'inactive-tab-a'}` } onClick={ () => changeTab('form') }>Postula aquí</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row d-flex justify-content-center">
            {
              tab === 'info' ?
              <div className="col-md-10 py-layout-3">
                <div className="p-layout-3 hidden-form">
                  <div
                    className="markdown-content pb-7 font-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </div>
              :
              <div className={`col-md-12`} >
                <div  id={tab === 'form'? 'grnhse_app' : ''}></div>
              </div>
            }
          </div>
        </div>
      </section>
      <Footer theme="primary" />
      </>
    )
  }
  return <IndexLayout 
  render={ content }
  navbarTheme="default"
  locale={ pathContext.locale } 
  location={location} />
}

export default WorkWithUsJob

export const query = graphql`
  query($job_id: Float!) {
    jobInfo: allGreenhouseJob(filter: {job_id: {eq: $job_id}}) {
      edges {
        node {
          active
          content
          id
          job_id
          live
          location {
            name
          }
          title
        }
      }
    }
  }
`